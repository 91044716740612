<template>
  <expansion-panel
    accordion
    :contentProps="{ eager: true }"
    :items="permissionPanels"
  >
    <template v-slot:header="{ item: { title } }">
      {{ title }}
    </template>
    <template v-slot:content="{ item: { fields } }">
      <v-row>
        <v-col
          v-for="(field, index) of fields"
          :cols="field.colSize || 3"
          class="submodulo-wrap"
          :key="index"
        >
          <v-checkbox
            dense
            :disabled="sending"
            hide-details
            :ripple="false"
            :indeterminate="isIndeterminate(field.rel.toEdit)"
            :input-value="isChecked(field.rel.toEdit)"
            @click.stop="doCheck(field.rel.toEdit)"
          >
            <template v-slot:label>
              <strong>{{ field.name }}</strong>
            </template>
          </v-checkbox>
          <v-checkbox
            v-for="(opt, optIndex) of field.rel.toEdit"
            v-model="value"
            class="pl-3"
            dense
            :disabled="sending"
            hide-details
            :key="optIndex"
            :label="opt.name"
            multiple
            :ripple="false"
            :value="opt.id"
            @change="changeHandler($event)"
          ></v-checkbox>
          <hr class="divisor" />
        </v-col>
      </v-row>
    </template>
  </expansion-panel>
</template>

<script>
import translation from "./translation.js";

export default {
  components: {
    "expansion-panel": () => import("@/components/expansion-panel.vue"),
  },
  computed: {
    permissionPanels: function () {
      return Object.entries(this.items)
        .reduce((acc, [mod, perms]) => {
          const panel = {
            title: this.translation[mod],
            fields: [],
          };

          const resources = perms
            .map(({ nome }) => nome)
            .reduce((obj, curr) => {
              const [, resource, action] = curr.split(".");

              if (!(resource in obj)) {
                obj[resource] = [];
              }

              obj[resource].push(action);
              return obj;
            }, {});

          panel.fields = Object.entries(resources)
            .map(([resource, actions]) => {
              const toEdit = actions
                .map((action) => ({
                  id: `${mod}.${resource}.${action}`,
                  name: this.translation[action],
                }))
                .sort(({ name: nameA }, { name: nameB }) => {
                  const ORDER = [this.translation.index, this.translation.create, this.translation.update, this.translation.delete];
                  const positionA = ORDER.indexOf(nameA) + 1 || 999;
                  const positionB = ORDER.indexOf(nameB) + 1 || 999;
                  return positionA - positionB;
                });

              return {
                key: "permissoes",
                name: resource === 'resource' ? this.translation[mod] : this.translation[resource],
                type: this.$fieldTypes.CHECKBOXES,
                column: true,
                rel: { toEdit, key: "id", name: "name" },
              };
            })
            .sort(({ name: nameA }, { name: nameB }) =>
              nameB === this.translation.dispendios ? -999 : nameA.localeCompare(nameB)
            );
          return [...acc, panel];
        }, [])
        .sort(({ title: titleA }, { title: titleB }) => {
          const ORDER = [
            this.translation.Geral,
            this.translation.Cadastro,
            this.translation.Dashboard,
            this.translation.Colaboradores,
            this.translation.Projetos,
            this.translation.Dispendios,
            this.translation.Timesheet,
            this.translation.Faturamento,
            this.translation.Importacoes,
          ];
          const a = ORDER.indexOf(titleA) + 1 || 999;
          const b = ORDER.indexOf(titleB) + 1 || 999;
          return a - b;
        });
    },
    translation: function () {
      const handler = {
        get(target, prop) {
          if (prop in target) {
            return target[prop];
          }
          console.warn(`Não há tradução para o termo ${prop}!!`);
          return prop;
        },
      };

      return new Proxy(translation, handler);
    },
  },
  methods: {
    changeHandler: function (selectedList) {
      const hasSomeImportPermission = selectedList.some((permission) => /Importacoes./.test(permission) && permission !== "Importacoes.resource.index");
      const hasImportResourceIndexPermission = !!selectedList.find((permission) => permission === "Importacoes.resource.index");

      if (hasSomeImportPermission && !hasImportResourceIndexPermission) {
        selectedList.push("Importacoes.resource.index");
      }

      this.$emit('change', selectedList);
    },
    doCheck: function (options) {
      const ids = options.map(({ id }) => id);
      let values = this.value;

      if (this.isChecked(options)) {
        values = this.value.filter((v) => !ids.includes(v));
      } else {
        ids.forEach((id) => {
          if (!this.value.includes(id)) {
            values.push(id);
          }
        });
      }

      this.changeHandler(values);
    },
    isChecked: function (options) {
      const values = options.map(({ id }) => id);
      return values.every((value) => this.value.includes(value));
    },
    isIndeterminate: function (options) {
      const values = options.map(({ id }) => id);
      return !this.isChecked(options) && values.some((value) => this.value.includes(value));
    },
  },
  model: {
    event: 'change',
    prop: 'value',
  },
  props: {
    items: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Array,
      default: () => ([]),
    },
    sending: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-input--selection-controls {
    margin-top: unset;
  }

  .submodulo-wrap {
    padding-bottom: 1.5rem;
  }

  hr.divisor {
    border-top: thin solid hsl(228deg 100% 22.73% / 16%);
    border-radius: 5px;
    bottom: 1rem;
    margin: 0px auto;
    position: absolute;
    width: 80%;
  }
</style>
